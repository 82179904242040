import React, {useState, useEffect, createContext} from 'react'
import {BrowserRouter, Route, Routes, useLocation, Navigate} from 'react-router-dom'
// eslint-disable-next-line no-unused-vars
import {CSSTransition, TransitionGroup, SwitchTransition} from 'react-transition-group'
import Home from './home'
import About from './about'
import Test from './test'
import ContactPage from './contact'
import Footer from '../sections/Footer'
import LoadingPage from '../sections/LoadingPage'
// import Nav from '../sections/NavBar/Nav'
import '../styles/animations.css'
import Nav from '../sections/NavBar/Nav'
// import NotFound from './notFound'
// Create a context for loading state
export const LoadingContext = createContext()

// Create a context for navigation state
export const NavContext = createContext()

const AnimatedRoutes = () => {
	const location = useLocation()
	const nodeRef = React.useRef(null)

	return (
		<SwitchTransition>
			<CSSTransition nodeRef={nodeRef} key={location.pathname} classNames='fade' timeout={{enter: 500, exit: 500}}>
				<div ref={nodeRef}>
					<Routes location={location}>
						<Route path='/about' element={<About />} />
						<Route path='/test' element={<Test />} />
						<Route path='/contact' element={<ContactPage />} />
						<Route path='/' element={<Home />} />
						<Route path='/*' element={<Navigate to='/' />} />
					</Routes>
				</div>
			</CSSTransition>
		</SwitchTransition>
	)
}

const Router = () => {
	const [isLoaded, setIsLoaded] = useState(true)
	const [onHeroSection, setOnHeroSection] = useState(true)
	const [defaultShow, setDefaultShow] = useState(true)
	const [hideOnScroll, setHideOnScroll] = useState(true)

	useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoaded(true)
			document.documentElement.style.removeProperty('overflow-y')
			document.documentElement.style.removeProperty('width')
			document.documentElement.style.removeProperty('padding-right')
		}, 300) // Adjust this timeout as needed

		return () => clearTimeout(timer)
	}, [isLoaded])

	return (
		<LoadingContext.Provider value={{isLoaded, setIsLoaded}}>
			<NavContext.Provider value={{onHeroSection, setOnHeroSection, defaultShow, setDefaultShow, hideOnScroll, setHideOnScroll}}>
				<BrowserRouter>
					<LoadingPage />
					<>
						<Nav /> {/* Use onHeroSection etc. from context */}
						<AnimatedRoutes />
						<Footer />
					</>
				</BrowserRouter>
			</NavContext.Provider>
		</LoadingContext.Provider>
	)
}

export default Router
