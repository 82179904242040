import React, {useContext, useEffect} from 'react'
import Contact from '../../sections/Contact'
import {LoadingContext, NavContext} from '..'

const ContactPage = () => {
	const {setIsLoaded} = useContext(LoadingContext) // Use the context
	const {setHideOnScroll, setOnHeroSection} = useContext(NavContext) // Access the context

	useEffect(() => {
		setIsLoaded(true)
		setHideOnScroll(false)
		setOnHeroSection(false)

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div className='contact-page'>
			<Contact />
		</div>
	)
}

export default ContactPage
