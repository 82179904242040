import React from 'react'
import Border from '../components/Border'
import {GreenArrowDown} from '../images'

import HowItWorks_mobile1_PNG from '../images/howitworks/HowItWorks_mobile1.png'
import HowItWorks_mobile1_WEBP from '../images/howitworks/HowItWorks_mobile1.webp'
import HowItWorks_mobile2_PNG from '../images/howitworks/HowItWorks_mobile2.png'
import HowItWorks_mobile2_WEBP from '../images/howitworks/HowItWorks_mobile2.webp'
import HowItWorks_mobile3_PNG from '../images/howitworks/HowItWorks_mobile3.png'
import HowItWorks_mobile3_WEBP from '../images/howitworks/HowItWorks_mobile3.webp'
import HowItWorks_desktop_PNG from '../images/howitworks/HowItWorks_desktop.png'
import HowItWorks_desktop_WEBP from '../images/howitworks/HowItWorks_desktop.webp'
import ImageLoader from '../utils/imageLoader'

const steps = {
	step1: {
		text: "Press 'get mine now' to get the ball rolling.",
		images: {
			png: HowItWorks_mobile1_PNG,
			webp: HowItWorks_mobile1_WEBP,
		},
	},
	step2: {
		text: 'We discuss the details (sending the best photos etc.) in order to make your caricature the best it can be!',
		images: {
			png: HowItWorks_mobile2_PNG,
			webp: HowItWorks_mobile2_WEBP,
		},
	},
	step3: {
		text: 'Within 3-7 days the art piece will be done and sent via preferred method (if printed, shipping time may very pending on your location.)',
		images: {
			png: HowItWorks_mobile3_PNG,
			webp: HowItWorks_mobile3_WEBP,
		},
		note: 'Note: high resolution digital file is sent together with the print.',
	},
	desktop: {
		images: {
			png: HowItWorks_desktop_PNG,
			webp: HowItWorks_desktop_WEBP,
		},
	},
}

const Paragraph = ({text}) => {
	return <p className='w-3/4 sm:w-1/2 self-center mt-12'>{text}</p>
}

const IndividualStep = ({step}) => {
	return (
		<div className='relative flex flex-col justify-center text-left h-auto md:h-full'>
			<ImageLoader
				artPNG={step.images.webp}
				artWEBP={step.images.png}
				// placeholder={<></>}
				altTitle={'How to get your own caricature, visual explanation.'}
				className={'object-contain max-h-[50vh] h-fullh-fit w-full'}
			/>
			<Paragraph text={step.text} />
		</div>
	)
}

const HowItWorksSection = () => {
	return (
		<div className='relative flex flex-col justify-center items-center text-center'>
			<h1 className='section-title max-w-screen-lg'>HOW IT WORKS</h1>
			{/* <h1 className='bg-clip-text text-transparent bg-gradient-to-r from-pink-500 to-violet-500 section-title'>HOW IT WORKS</h1> */}

			{/* Background div that takes up 90vh, leaving space at the bottom for the next section */}
			<div className='relative flex flex-col md:mb-16 max-w-screen-lg px-2 doodle'>
				<div className='hidden md:flex flex-col md:mx-4'>
					<ImageLoader
						artPNG={steps.desktop.images.png}
						artWEBP={steps.desktop.images.webp}
						// placeholder={null}
						altTitle={'How to get your own caricature, visual explanation.'}
						className={''}
						size={{width: '1849', height: '1062'}}
						test={true}
					/>
					<div className='grid grid-cols-3 grid-rows-2 gap-4 mt-8'>
						<div>{steps.step1.text}</div>
						<div>{steps.step2.text}</div>
						<div>{steps.step3.text}</div>
						<div className='col-start-3 row-start-2'>{steps.step3.note}</div>
					</div>
				</div>
				<div className='relative md:hidden grid grid-cols-1 auto-cols-fr select-none w-full'>
					<div>
						<IndividualStep step={steps.step1} />
						<div className='grid justify-end mr-[5vw]'>
							<GreenArrowDown className='w-12 h-12 rotate-45' />
						</div>
					</div>
					<div>
						<IndividualStep step={steps.step2} />
						<div className='grid justify-end mr-[5vw]'>
							<GreenArrowDown className='w-12 h-12 rotate-45' />
						</div>
					</div>
					<div className='relative flex flex-col justify-center text-left h-auto md:h-full'>
						<IndividualStep step={steps.step3} />
						<Paragraph text={steps.step3.note} />
					</div>
				</div>
			</div>
			<div className='mt-8'>
				<Border />
			</div>
		</div>
	)
}

export default HowItWorksSection
