import React, {useContext, useEffect, useRef, useState} from 'react' // Added useState
import HeroSection from '../../sections/Hero'
import ProsSection from '../../sections/Pros'
import HowItWorksSection from '../../sections/HowItWorks'
// eslint-disable-next-line no-unused-vars
import Gallery2 from '../../sections/Gallery2'
import EnoughTalk from '../../sections/EnoughTalk'
import FAQSection from '../../sections/FAQ'
// import Contact from '../../sections/Contact'
// import Footer from '../../sections/Footer'
import {LoadingContext, NavContext} from '../index' // Import the context
import {ContactSection} from '../../sections/Contact/ContactSection'
// eslint-disable-next-line no-unused-vars
import ScrollToTop from '../../utils/scrollToTop'
import {useLocation} from 'react-router-dom'
import {scrollIntoView} from '../../utils/scroll_into_view'
import Gallery3 from '../../sections/Gallery3'

const Home = () => {
	const location = useLocation()
	useEffect(() => {
		if (location.hash) scrollIntoView(null, location.hash)
	}, [location])

	const {setIsLoaded} = useContext(LoadingContext) // Use the context
	const heroRef = useRef(null) // Reference to the hero section
	// eslint-disable-next-line no-unused-vars
	const {setOnHeroSection, onHeroSection, setDefaultShow, setHideOnScroll} = useContext(NavContext) // Access the context
	const [isNavVisible, setIsNavVisible] = useState(onHeroSection) // Local state for navbar visibility

	useEffect(() => {
		setHideOnScroll(true)
		setDefaultShow(false)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	// useEffect(() => {
	// 	// Check if there's a hash in the URL
	// 	if (location.hash) {
	// 		const id = window.location.hash.substring(1) // Get the ID from the hash
	// 		const element = document.getElementById(id) // Find the element by ID
	// 		if (element) {
	// 			element.scrollIntoView({behavior: 'smooth'}) // Scroll to the element
	// 		}
	// 		scrollIntoView()
	// 	}
	// }, []) // Run this effect only once on load

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					const heroVisibleRatio = entry.intersectionRatio
					// const isHeroVisible = heroVisibleRatio > 0 // Check if hero is visible

					if (heroVisibleRatio === 0) {
						// Show navbar when hero is 100% out of view
						setOnHeroSection(false)
						setIsNavVisible(true)
					} else if (heroVisibleRatio < 0.1 && isNavVisible) {
						// Hide navbar when 10% or more of hero is visible
						setOnHeroSection(true)
						setIsNavVisible(false)
					} else if (heroVisibleRatio >= 0.1 && !isNavVisible) {
						// Keep navbar hidden while hero is visible
						setOnHeroSection(true)
						setIsNavVisible(false)
					}
				})
			},
			{threshold: [0, 0.1]} // Trigger at 0% and 10% visibility
		)

		const nodeRef = heroRef?.current
		if (nodeRef) observer.observe(nodeRef)
		return () => observer.disconnect()
	}, [heroRef, setOnHeroSection, isNavVisible])

	return (
		<div className='fade-this'>
			{/* <ScrollToTop /> */}
			{/* <Nav defaultShow={false} onHeroSection={onHeroSection} hideOnScroll={true} /> */}
			{/* Conditionally render Nav */}
			<div ref={heroRef}>
				<HeroSection setIsImageLoaded={setIsLoaded} />
			</div>
			<ProsSection />
			<HowItWorksSection />
			{/* <Gallery2 /> */}
			<Gallery3 />
			<EnoughTalk />
			<FAQSection />
			{/* <Contact /> */}
			<ContactSection />
			{/* <Footer /> */}
		</div>
	)
}

export default Home
