/* eslint-disable no-unused-vars */
import axios from 'axios'
import {Form, Formik, useField} from 'formik'
import React, {useEffect, useState, useRef} from 'react'
import * as Yup from 'yup'
import {apiBaseUrl} from '../../utils/config'
import './contactForm.css'
import CustomGlobalButton from '../../components/CustomGlobalButton'
import {useLocation, useNavigate} from 'react-router-dom' // Ensure useHistory is imported
import {useLocalStorage} from '../../hooks/useLocalStorage' // Import the useLocalStorage hook

// const prode_mode = process.env.NODE_ENV === 'production' ? true : false
// const apiBaseUrl = !prode_mode ? 'http://localhost:5000' : 'https://api.adelezstudio.com'

// const phone = '000'
// const whatsApp_endPoint = (text) => `https://api.whatsapp.com/send?phone=${phone}&text=${text}`
// const whatsApp_endPoint2 = (text) => `https://wa.me/${phone}?text=${text}`
// // const telegram_endPoint = (text) => `https://t.me/share/url?to=${phone}&text=${text}`
// // const telegram_endPoint2 = (text) => `tg://msg?text=${text}&to=${phone}`
// const telegram_endPoint3 = () => `https://t.me/light_wing`

const telegram_endPoint = (text) => `tg://user?id=610944439&text=${text}`

// //this works, but will require a bot that is always running
// //that will accept the code and connect it to the backend to forward it to Adele
// // this will take some extra time to do
// //in the meantime, we can show the user a nicely stylized message in markdown copy bubble, and ask them to copy the message and paste it into the chat that will open...
// // const telegram_endPoint4 = (code) => `https://t.me/triviabot?start=${code}`

const email_endPoint = (subject, message) => `mailto:adele@gmail.com?cc=&bcc=&subject=${subject}&body=${message}`

const MyTextInput = ({label, ...props}) => {
	// useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
	// which we can spread on <input> and also replace ErrorMessage entirely.
	const [field, meta] = useField(props)
	return (
		<>
			<label htmlFor={props.name}>
				<span>{label}</span>
				<div className='relative form_field'>
					<input
						className={`input_area peer ${meta.touched ? (meta.error ? 'invalid border-e_red' : 'valid border-e_green') : ''}`}
						{...field}
						{...props}
					/>
					<span className='focus-border'>
						<i></i>
					</span>
				</div>
				{meta.touched && meta.error ? <div className='text-e_red'>{meta.error}</div> : null}
			</label>
		</>
	)
}
const MyTextArea = ({label, ...props}) => {
	const [field, meta] = useField(props)
	// console.log('MyTextArea', field, meta)
	return (
		<>
			<label htmlFor={props.name}>
				<span>{label}</span>
				<div className='relative form_field'>
					<textarea className={`input_area ${meta.touched ? (meta.error ? 'invalid' : 'valid') : ''}`} {...field} {...props} />
					<span className='focus-border'>
						<i></i>
					</span>
				</div>
				{meta.touched && meta.error ? <div className='error'>{meta.error}</div> : null}
			</label>
		</>
	)
}
const MySelect = ({label, ...props}) => {
	const [field, meta] = useField(props)
	return (
		<>
			<label htmlFor={props.name}>
				<span>{label}</span>
				<div className='relative form_field'>
					<select className={`input_area ${meta.touched ? (meta.error ? 'invalid' : 'valid') : ''}`} {...field} {...props} />
					<span className='focus-border'>
						<i></i>
					</span>
				</div>
				{meta.touched && meta.error ? <div className='error'>{meta.error}</div> : null}
			</label>
		</>
	)
}
const MyFiles = ({label, ...props}) => {
	const [field, meta] = useField(props)
	return (
		<>
			<label htmlFor={props.name}>
				<span>{label}</span>
				<div className='relative form_field'>
					{/* required */}
					<input
						type='file'
						name='Files'
						multiple
						className={`input_area ${meta.touched ? (meta.error ? 'invalid' : 'valid') : ''}`}
						{...field}
						{...props}
					/>
					<span className='focus-border'>
						<i></i>
					</span>
				</div>
				{meta.touched && meta.error ? <div className='error'>{meta.error}</div> : null}
			</label>
		</>
	)
}
const MyHiddenField = ({...props}) => {
	const [field, meta] = useField(props)
	return (
		<>
			<input className='hidden' {...field} {...props} />
		</>
	)
}
const formOptions = {
	Caricature: {
		value: 'Get a caricature',
		placeholder:
			'Hi, I want a couple caricature of me and my wife for our anniversary, with writing underneath saying “no take backsies were stuck together forever. Happy anniversary.',
	},
	Live: {
		value: 'Live event inquire',
		placeholder: 'I have this great event coming up and would love to have you as part of it.',
	},
	Feedback: {
		value: 'Provide Feedback/Leave a Review',
		placeholder: 'Share your thoughts and opinions...',
	},
	Developer: {
		value: 'Contact the Site Developer',
		placeholder: 'Ask Meir anything...',
	},
	Other: {
		value: 'Other',
		placeholder: 'Once upon a time there was a princess who lived at the top of a glass hill. BTW, I would like to use specific colors.',
	},
}
const formKeys = Object.keys(formOptions)

// New tierKeys constant added
const tierKeys = ['basic', 'standard', 'premium'] // Define the tier options

const defaultFormValues = {
	// Extract default values to a constant
	name: '',
	email: '',
	subject: formKeys[0],
	message: '',
	source: '',
	tier: 'none',
}
export const FormContainer = ({onChange, setMessageSent, messageSent, handleBlur, handleFocus, ...props}) => {
	const [sending, setSending] = useState(false)
	const [submitInProgress, setSubmitInProgress] = useState(false)
	const [message, setMessage] = useState('')
	const [error, setError] = useState('')
	const [formValues, setFormValues] = useLocalStorage('formValues', defaultFormValues) // Initialize local storage for form values

	const location = useLocation()
	const navigate = useNavigate() // Initialize navigate

	// Update initialValues to use values from local storage
	const initialValues = {
		...formValues,
		source: new URLSearchParams(location.search).get('from') || defaultFormValues.source,
		tier: new URLSearchParams(location.search).get('get')?.toLowerCase() || defaultFormValues.tier,
	}

	useEffect(() => {
		navigate(location.pathname, {replace: true})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []) // Keep the dependency on location

	const handleSubmit = (values, {setSubmitting, setErrors, status, resetForm}) => {
		const changeSendingStatus = (status) => {
			setSubmitting(status)
			setSending(status)
			setSubmitInProgress(status)
		}
		// e.preventDefault()
		changeSendingStatus(true)

		axios
			.post(`${apiBaseUrl}/contact`, values)
			.then((res) => {
				resetForm()
				setMessage(res?.data?.message || 'Message sent ... :)')
				setFormValues(defaultFormValues) // Clear form values after message is sent
				changeSendingStatus(false)
				setMessageSent(true)
			})
			.catch((e) => {
				console.log('error while submitting e:', e)
				setError(e?.response?.data?.error?.message || 'Some error occurred')
				changeSendingStatus(false)
			})
	}

	// Update form values on change
	const handleChange = (values) => {
		setFormValues(values)
	}

	if (messageSent) {
		return (
			<div className='flex flex-col justify-around max-w-[400px] lg:max-w-lg w-full'>
				<h2 className='text-xl'>Thank you!</h2>
				<div>
					We'll be in touch soon.
					<br />
					We can't wait to bring your caricature to life.
					<br />
					<br />
					{message.toString()}
				</div>
			</div>
		)
	}

	const subjectOptions = formKeys.map((key) => (
		<option key={key} value={key}>
			{formOptions[key].value}
		</option>
	))

	return (
		<Formik initialValues={initialValues} validate={validate} onSubmit={handleSubmit}>
			{({isSubmitting, errors, values, onSubmit}) => (
				<Form
					className={'max-w-[400px] lg:max-w-[500px] w-full'}
					id='contact-form'
					onChange={(e) => {
						handleChange(values)
						onChange(e)
					}}
					onBlur={handleBlur}
					onFocus={handleFocus}>
					<MyHiddenField type='text' name='sbox' value='' />
					<MyHiddenField type='text' name='source' value={props.source} />
					<MySelect label='Subject:' name='subject'>
						<option value='none' disabled>
							Select an option
						</option>
						{subjectOptions}
					</MySelect>
					{values.subject === formKeys[0] && (
						<MySelect label='Tier:' name='tier'>
							<option value='none' disabled={values.tier !== 'none'}>
								Select an option
							</option>
							{tierKeys.map((key) => (
								<option key={key} value={key}>
									{key.charAt(0).toUpperCase() + key.slice(1)} {/* Capitalize the tier key */}
								</option>
							))}
						</MySelect>
					)}
					{formKeys.includes(values.subject) ? (
						<>
							<MyTextInput label='Name:' name='name' type='text' placeholder='Adele' />

							<MyTextInput label='Email:' name='email' type='email' placeholder='adele@example.com' />

							<MyTextArea label='Message:' name='message' rows='6' placeholder={formOptions[values.subject]?.placeholder || 'lmk wyt'} />
							{/* <button
								type='submit'
								onClick={onSubmit}
								style={{'--btn-color': `${Object.keys(errors).length === 0 ? '#22c55e' : '#e11d48'}`}}
								disabled={submitInProgress}
								className={`self-start sm:self-center bg-black text-black`}>
								{sending ? 'Sending...' : 'Send letter =>'}
							</button> */}
							<CustomGlobalButton
								type='submit'
								onClick={onSubmit}
								disabled={submitInProgress}
								contact={true}
								text={sending ? 'Sending...' : 'Send Letter =>'}
								className=''
							/>
							{error && (
								<div className='text-e_red' role='alert'>
									{error.toString()}
								</div>
							)}
						</>
					) : null}
				</Form>
			)}
		</Formik>
	)
}

// function validate(values) {
const validate = (values) => {
	const validationSchema = getValidationSchema(values)
	try {
		validationSchema.validateSync(values, {abortEarly: false})
		return {}
	} catch (error) {
		return getErrorsFromValidationError(error)
	}
}
function getErrorsFromValidationError(validationError) {
	const FIRST_ERROR = 0
	return validationError.inner.reduce((errors, error) => {
		// console.log('getErrorsFromValidationError', errors)
		return {
			...errors,
			[error.path]: error.errors[FIRST_ERROR],
		}
	}, {})
}
function getValidationSchema(values) {
	// console.log('getValidationSchema', values)
	// console.log('values.subject === subjectOptions[0]', values.subject === subjectOptions[0])

	return Yup.object().shape({
		// showEmail: Yup.boolean(),
		// email: Yup.string()
		// 	.email('Email is not valid!')
		// 	.when('showEmail', {
		// 		is: true,
		// 		then: Yup.string().required('Email is required!'),
		// 	}),
		email: Yup.string().email().required('Email is required!'),
		name: Yup.string().min(2, "That's a very short name...").required('Name is required!'),
		subject: Yup.string()
			// .oneOf([subjectOptions], 'Please select a subject, if you want to change later, just let me know.')
			.required('Please select a subject, can be changed later!'),
		tier: values.subject !== formKeys[0] ? '' : Yup.string().oneOf([tierKeys], 'Please select an option'), //.required('Password confirmation is required!'),
		// tier:
		// 	values.subject === formKeys[0] //TODO if ever changing the words, or get it directly formOptions keys
		// 		? Yup.string().oneOf([tierOptions], 'Please select one of the options').required('Please select an option')
		// 		: false, //.required('Password confirmation is required!'),
		message: Yup.string()
			.min(10, 'Please give me some more details for what you want...')
			.max(
				400,
				'Thank you for writing so much. The form is limited to 400 characters. Please review your message and shorten it to fit within this limit so that I can read it in a timely manner.'
			)
			.required('Forgot Something? (we need more info)'),
		// consent: Yup.bool()
		// 	.test('consent', 'You have to agree with our Terms and Conditions!', (value) => value === true)
		// 	.required('You have to agree with our Terms and Conditions!'),
	})
}
