/* eslint-disable no-unused-vars */
import React, {useEffect, useRef, useState} from 'react'
// import { useEffect, useState } from 'react';
import Border from '../components/Border'
// import {
// 	Char_AllSmiles,
// 	Char_AxlRose,
// 	Char_BradPitt,
// 	Char_CoolBlackLady,
// 	Char_JimCarry,
// 	Char_TimCha,
// 	Hero_ShowGirl,
// 	HeroBG_Chars_D,
// 	HeroBG_Chars_M,
// 	HeroBG_D,
// 	HeroBG_M,
// 	LogoTxt,
// } from '../img'

// import BG_D_full from '../images/hero/HeroBG.D_full.1861x1373.png'
// import BG_D_PNG from '../images/hero/HeroBG.D.1861x1373.png'
// import BG_D_JPG from '../images/hero/HeroBG.D.1861x1373.jpeg'
// import BG_D_WEBP from '../images/hero/HeroBG.D.1861x1373.webp'
// import BG_Dmin_PNG from '../images/hero/HeroBG.Dmin.1543x1283.png'
// import BG_Dmin_JPG from '../images/hero/HeroBG.Dmin.1543x1283.jpeg'
// import BG_Dmin_WEBP from '../images/hero/HeroBG.Dmin.1543x1283.webp'

// import BG_M_full from '../images/hero/HeroBG.M_full.1095x2183.png'
// import BG_M_PNG from '../images/hero/HeroBG.M.822x1638.png'
// import BG_M_JPG from '../images/hero/HeroBG.M.822x1638.jpeg'
// import BG_M_WEBP from '../images/hero/HeroBG.M.822x1638.webp'
// import BG_Mmin_PNG from '../images/hero/HeroBG.Mmin.744x1556.png'
// import BG_Mmin_JPG from '../images/hero/HeroBG.Mmin.744x1556.jpeg'
// import BG_Mmin_WEBP from '../images/hero/HeroBG.Mmin.744x1556.webp'

import Hero_maxDesktop_BG_JPG from '../images/hero/v3/Hero_maxDesktop_BG-optimised.jpeg'
import Hero_maxDesktop_BG_WEBP from '../images/hero/v3/Hero_maxDesktop_BG-optimised.webp'
import Hero_minDesktop_BG_WEBP from '../images/hero/v3/Hero_minDesktop_BG-optimised.webp'
import Hero_minDesktop_BG_JPG from '../images/hero/v3/Hero_minDesktop_BG-optimised.jpeg'

import Hero_maxDesktop_Char_PNG from '../images/hero/v3/Hero_maxDesktop_Char-optimised.png'
import Hero_maxDesktop_Char_WEBP from '../images/hero/v3/Hero_maxDesktop_Char-optimised.webp'
import Hero_minDesktop_Char_WEBP from '../images/hero/v3/Hero_minDesktop_Char-optimised.webp'
import Hero_minDesktop_Char_PNG from '../images/hero/v3/Hero_minDesktop_Char-optimised.png'

import Hero_maxMobile_BG_JPG from '../images/hero/v3/Hero_maxMobile_BG-optimised.jpeg'
import Hero_maxMobile_BG_WEBP from '../images/hero/v3/Hero_maxMobile_BG-optimised.webp'
import Hero_minMobile_BG_WEBP from '../images/hero/v3/Hero_minMobile_BG-optimised.webp'
import Hero_minMobile_BG_JPG from '../images/hero/v3/Hero_minMobile_BG-optimised.jpeg'

import Hero_maxMobile_Char_PNG from '../images/hero/v3/Hero_maxMobile_Char-optimised.png'
import Hero_maxMobile_Char_WEBP from '../images/hero/v3/Hero_maxMobile_Char-optimised.webp'
import Hero_minMobile_Char_WEBP from '../images/hero/v3/Hero_minMobile_Char-optimised.webp'
import Hero_minMobile_Char_PNG from '../images/hero/v3/Hero_minMobile_Char-optimised.png'

import CustomGlobalButton from '../components/CustomGlobalButton'
import {useNavigate} from 'react-router-dom'
import {scrollIntoView} from '../utils/scroll_into_view'
import useScreenDetector from '../hooks/useScreenDetector'

const HeresWhyText = ({className}) => {
	return (
		<h1 className={`${className ? className : ''} section-title font-lucky !mt-0`}>
			<span className='text-e_blue pr-4 xs:pr-8'>{"HERE'S"}</span>
			<span className='text-e_red'>{'WHY'}</span>
		</h1>
	)
}
const ImageLayer = ({
	fileType = 'image/jpeg' | 'image/png',
	className,
	Hero_minMobile_WEBP,
	Hero_maxMobile_WEBP,
	Hero_minDesktop_WEBP,
	Hero_maxDesktop_WEBP,
	Hero_minMobile,
	Hero_maxMobile,
	Hero_minDesktop,
	Hero_maxDesktop,
	onClick,
}) => {
	const {isMobile, isMinDesktop, isTablet, isMinMobile, isDesktop} = useScreenDetector()

	return (
		<picture className='z-10 w-full '>
			{/* WebP sources */}
			{isMinMobile && <source type='image/webp' srcSet={Hero_minMobile_WEBP} />}
			{isMobile && <source type='image/webp' srcSet={Hero_maxMobile_WEBP} />}
			{(isMinDesktop || isTablet) && <source type='image/webp' srcSet={Hero_minDesktop_WEBP} />}
			{isDesktop && <source type='image/webp' srcSet={Hero_maxDesktop_WEBP} />}
			{/* JPEG/PNG sources */}
			{isMinMobile && <source type={fileType} srcSet={Hero_minMobile} />}
			{isMobile && <source type={fileType} srcSet={Hero_maxMobile} />}
			{(isMinDesktop || isTablet) && <source type={fileType} srcSet={Hero_minDesktop} />}
			{isDesktop && <source type={fileType} srcSet={Hero_maxDesktop} />}

			<img
				className={`${className} w-full`}
				alt='Hero Background'
				loading='eager'
				onClick={onClick}
				// width={isMobile ? 822 : 1861} // Dynamic width
				// height={isMobile ? 1638 : 1373} // Dynamic height
				src={'./Loading.svg'} // Use the state for the src
				// onLoad={() => setIsImageLoaded(true)}
			/>
		</picture>
	)
}
const HeroSection = ({setIsImageLoaded}) => {
	// eslint-disable-next-line no-unused-vars
	const {isMobile, isMinDesktop, isTablet, isMinMobile, isDesktop} = useScreenDetector()

	// const [imgUrl, setImgUrl] = useState(isMobile ? BG_M_PNG : BG_D_PNG)

	const navigate = useNavigate()
	const gotoContact = () => {
		scrollIntoView(null, '#contact')
		navigate(`/contact?from=hero`)
	}

	return (
		<>
			<div className='relative flex justify-center items-center mx-auto w-[100svw] flex-col '>
				<div className='w-full min-h-[1px] relative flex justify-center items-center flex-col '>
					<ImageLayer
						fileType={'image/jpeg'}
						className={'pointer-events-none h-full'}
						Hero_minMobile_WEBP={Hero_minMobile_BG_WEBP}
						Hero_maxMobile_WEBP={Hero_maxMobile_BG_WEBP}
						Hero_minDesktop_WEBP={Hero_minDesktop_BG_WEBP}
						Hero_maxDesktop_WEBP={Hero_maxDesktop_BG_WEBP}
						Hero_minMobile={Hero_minDesktop_BG_JPG}
						Hero_maxMobile={Hero_minDesktop_BG_JPG}
						Hero_minDesktop={Hero_minDesktop_BG_JPG}
						Hero_maxDesktop={Hero_maxDesktop_BG_JPG}
					/>
					{/* button and show girl */}
					<ImageLayer
						fileType={'image/png'}
						className={'absolute bottom-0 hover:cursor-pointer'}
						Hero_minMobile_WEBP={Hero_minMobile_Char_WEBP}
						Hero_maxMobile_WEBP={Hero_maxMobile_Char_WEBP}
						Hero_minDesktop_WEBP={Hero_minDesktop_Char_WEBP}
						Hero_maxDesktop_WEBP={Hero_maxDesktop_Char_WEBP}
						Hero_minMobile={Hero_minDesktop_Char_PNG}
						Hero_maxMobile={Hero_minDesktop_Char_PNG}
						Hero_minDesktop={Hero_minDesktop_Char_PNG}
						Hero_maxDesktop={Hero_maxDesktop_Char_PNG}
						onClick={gotoContact}
					/>
					{/* <div
					className={`absolute z-20 -translate-x-1/2 -translate-y-1/2 opacity-0`}
					// -webkit-transform: translate(calc(-1 * var(--hotspot-translate-x)),calc(-1 * var(--hotspot-translate-y)));\
					// -ms-transform: translate(calc(-1 * var(--hotspot-translate-x)),calc(-1 * var(--hotspot-translate-y)));\
					style={{
						'--hotspot-translate-x': translateX,
						'--hotspot-translate-y': translateY,
						left: translateX,
						top: translateY,
						// transform: 'translate(calc(-1 * var(--hotspot-translate-x)),calc(-1 * var(--hotspot-translate-y)))',
					}}>
					<div
						className={`rounded-full font-lucky text-center tracking-[0.15px] 
							appearance-none select-none cursor-pointer
							 outline-white bg-white bg-opacity-40`}
						style={{
							padding: textPadding,
							fontSize: textSize,
						}}
						onClick={gotoContact}>
						get mine now
					</div>
					<CustomGlobalButton onClick={gotoContact} className='font-lucky' hero={true} />
				</div> */}
					<div className={'absolute bottom-0 z-20'}>
						<HeresWhyText />
					</div>
				</div>
				<Border />
			</div>
		</>
	)
}

export default HeroSection
