import React, {useRef, useEffect, useState} from 'react'
// import {toast} from 'react-toastify'
// eslint-disable-next-line no-unused-vars
import {useNotification} from '../context/NotificationContext'

const ImageLoader = ({altTitle, artPNG, artWEBP, artJPG, className, placeholder, size = {width: 0, height: 0}, test = false}) => {
	const imgRef = useRef(null)
	const [isVisible, setIsVisible] = useState(false)
	const [loadedImage, setLoadedImage] = useState(null) // Track the loaded image

	useEffect(() => {
		const observer = new IntersectionObserver(
			(entries) => {
				entries.forEach((entry) => {
					if (entry.isIntersecting) {
						setIsVisible(true)
						observer.unobserve(entry.target)
					}
				})
			},
			{rootMargin: test ? '0px 0px -350px 0px' : '0px 0px 50px 0px'}
		)

		const currentImg = imgRef.current
		if (currentImg) {
			observer.observe(currentImg)
		}

		return () => {
			if (currentImg) {
				observer.unobserve(currentImg)
			}
		}
	}, [test])

	const loadingSVGPath = '/Loading.svg'
	const thumbnail = placeholder || loadingSVGPath
	const aspectRatio = size.width && size.height ? size.height / size.width : undefined
	const aspectRatioPercentage = (aspectRatio * 100).toFixed(2)
	const divStyle =
		!isVisible && !placeholder && aspectRatio !== undefined
			? {
					position: 'relative',
					width: '100%',
					paddingTop: aspectRatioPercentage + '%',
					backgroundColor: '#f0f0f0',
					overflow: 'hidden',
			  }
			: undefined

	const imgStyle =
		!isVisible && !placeholder && aspectRatio !== undefined
			? {
					position: 'absolute',
					top: 0,
					left: 0,
					width: '100%',
					height: '100%',
					objectFit: 'cover',
			  }
			: undefined

	// const {setToast} = useNotification()
	const handleImageLoad = () => {
		if (!loadedImage && isVisible) {
			// Check if the image hasn't been loaded yet
			// setToast('loaded ' + altTitle)

			setLoadedImage(true) // Set loaded image state to true
		}
	}

	return (
		<div style={divStyle}>
			<picture style={imgStyle}>
				{!isVisible && <source type='image/svg' srcSet={thumbnail} />}
				{artWEBP && <source type='image/webp' srcSet={isVisible ? artWEBP : thumbnail} />}
				{artPNG && <source type='image/png' srcSet={isVisible ? artPNG : thumbnail} />}
				{artJPG && <source type='image/jpeg' srcSet={isVisible ? artJPG : thumbnail} />}
				<img
					ref={imgRef}
					src={isVisible ? (artPNG ? artPNG : artJPG) : thumbnail} // Use Loading.svg from public
					loading='lazy'
					alt={altTitle}
					style={imgStyle}
					onLoad={handleImageLoad} // Updated onLoad handler
					className={`${className} transition-opacity duration-500 ${isVisible ? 'opacity-100 blur-0' : 'opacity-70 blur-lg'}`}
				/>
			</picture>
		</div>
	)
}

export default ImageLoader
