/* eslint-disable no-unused-vars */
import React, {useState, useRef, useEffect} from 'react'
import {basicGalleryImages, ForGallery, premiumGalleryImages, standardGalleryImages} from '../../images'
import ImageLoader from '../../utils/imageLoader' // Import the ImageLoader component
import './index.css'

// Import additional doodle images
// import GalleryBlackWhiteLeftPNG from '../../images/gallery/GalleryBlackWhiteLeft.png'
import GalleryBlackWhiteLeftWEBP from '../../images/gallery/GalleryBlackWhiteLeft.webp'
// // import GalleryBlackWhiteRightPNG from '../../images/gallery/GalleryBlackWhiteRight.png'
import GalleryBlackWhiteRightWEBP from '../../images/gallery/GalleryBlackWhiteRight.webp'
// // import GalleryColorLeftPNG from '../../images/gallery/GalleryColorLeft.png'
import GalleryColorLeftWEBP from '../../images/gallery/GalleryColorLeft.webp'
// // import GalleryColorRightPNG from '../../images/gallery/GalleryColorRight.png'
import GalleryColorRightWEBP from '../../images/gallery/GalleryColorRight.webp'
// // import GalleryPainterlyLeftPNG from '../../images/gallery/GalleryPainterlyLeft.png'
import GalleryPainterlyLeftWEBP from '../../images/gallery/GalleryPainterlyLeft.webp'
// // import GalleryPainterlyRightPNG from '../../images/gallery/GalleryPainterlyRight.png'
import GalleryPainterlyRightWEBP from '../../images/gallery/GalleryPainterlyRight.webp'
import CustomGlobalButton from '../../components/CustomGlobalButton'
// import {useNavigate} from 'react-router-dom'
// import {scrollIntoView} from '../../utils/scroll_into_view'

const GallerySection = ({sectionTitle, subTitle, images, initialLimit = 12, text, darkTheme = false, highlights = [], useFor = true, doodles}) => {
	const galleryRef = useRef(null)

	return (
		<div className={`gallery-section max-w-[1440px] mx-auto ${!darkTheme ? 'bg-white' : `bg-black`} py-6`}>
			<div id={sectionTitle} ref={galleryRef}>
				{/* <GalleryGrid images={images} aspectSquare={!darkTheme} /> */}
			</div>

			<div className='info-section'>
				<ImageLoader
					altTitle='Doodle Left'
					artPNG={doodles.left?.png}
					artWEBP={doodles.left.webp}
					className={`hidden md:block ${doodles.left.extraClass}`}
				/>
				<div className='flex flex-col text-center justify-center items-center '>
					<h2 className={`${darkTheme ? 'text-white' : 'text-black'} mt-8 text-4xl font-lucky`}>
						{sectionTitle}
						<span className={`opacity-70 text-2xl text-e_gray`}>/{subTitle}</span>
					</h2>
					<p className={`${darkTheme ? 'text-white' : 'text-black'} w-full md:w-3/5 text-center md:text-start mt-8 flex-grow -pr-10`}>{text}</p>
					<ImageLoader
						altTitle='Doodle Left Mobile'
						artPNG={doodles.left?.png}
						artWEBP={doodles.left.webp}
						className={`w-full md:hidden block px-4 max-w-xs ${darkTheme ? 'order-last -mt-[40px]' : 'mt-4'}`}
					/>
					{useFor ? <ForGallery className={`${darkTheme ? 'invert' : ''} rotate-[25deg] w-16 h-16 mt-4 md:mt-12`} /> : <></>}
					<ul className='font-lucky text-e_red text-2xl mt-2 text-left'>
						{highlights.map((t, key) => {
							return <li key={key}>· {t}</li>
						})}
					</ul>

					{
						<CustomGlobalButton
							getItem={subTitle}
							from={'gallery'}
							gallery={darkTheme ? false : true}
							galleryPremium={darkTheme ? true : false}
							className='my-8'
						/>
					}
				</div>
				<ImageLoader
					altTitle='Doodle Right'
					artPNG={doodles.right?.png}
					artWEBP={doodles.right.webp}
					className={`hidden md:block ${doodles.right.extraClass}`}
				/>
			</div>
		</div>
	)
}

const gallerySections = (images) => [
	{
		images: images.basic,
		highlights: ['BIRTHDAY CARDS', 'INVITATIONS', 'THANK YOU CARDS'],
		text: 'Get a charming, custom designed caricature, stylized with that classic "cartoony" feel!',
		useFor: true,
		title: 'BLACK & WHITE',
		subTitle: 'BASIC',
		doodles: {
			right: {
				// png: GalleryBlackWhiteRightPNG,
				webp: GalleryBlackWhiteRightWEBP,
				extraClass: '',
			},
			left: {
				//png: GalleryBlackWhiteLeftPNG,
				webp: GalleryBlackWhiteLeftWEBP,
				extraClass: '',
			},
		},
	},
	{
		images: images.standard,
		highlights: ['GRADUATIONS', 'BIRTHDAYS', 'WEDDING GIFTS', 'YOUR FAVORITE PET'],
		text: (
			<>
				Elevate your caricature to the next level with energetic watercolors that instantly
				<br />
				Bring to life your caricature creating an everlasting impression.
			</>
		),
		useFor: true,
		title: 'COLOR',
		subTitle: 'STANDARD',
		doodles: {
			right: {
				//png: GalleryColorRightPNG,
				webp: GalleryColorRightWEBP,
				extraClass: '',
			},
			left: {
				//png: GalleryColorLeftPNG,
				webp: GalleryColorLeftWEBP,
				extraClass: '',
			},
		},
	},
	{
		images: images.premium,
		highlights: [],
		darkTheme: true,
		text: (
			<>
				Make a statement with a dynamic, painterly fine art piece, that captures the heart of your family.
				<br />
				An exceptionally meaningful gift, personalized to bring a smile on your face every time you see it.
			</>
		),
		useFor: false,
		title: 'PAINTERLY',
		subTitle: 'PREMIUM',
		doodles: {
			right: {
				//png: GalleryPainterlyRightPNG,
				webp: GalleryPainterlyRightWEBP,
				extraClass: '',
			},
			left: {
				//png: GalleryPainterlyLeftPNG,
				webp: GalleryPainterlyLeftWEBP,
				extraClass: '',
			},
		},
	},
]

const Gallery3 = () => {
	const [allImages, setGalleryImages] = useState({
		basic: [],
		standard: [],
		premium: [],
	})

	useEffect(() => {
		const loadImages = async () => {
			setGalleryImages({
				basic: basicGalleryImages,
				standard: standardGalleryImages,
				premium: premiumGalleryImages,
			})
		}

		loadImages()
	}, [])

	return (
		<div id='gallery' className='relative flex flex-col justify-start text-center items-center min-h-[100vh] w-screen'>
			{/* <div className='hidden md:flex justify-end w-full h-auto md:absolute right-0 top-0 z-10 md:-translate-y-[40%] pointer-events-none select-none touch-none'>
				<img src={Gallery_ShowBoy_lg} alt='Gallery Show Boy' className='doodle max-w-[40vw] h-full md:max-w-[30vw]' />
			</div> */}
			<h1 className='section-title'>GALLERY</h1>
			{gallerySections(allImages).map((section, key) => {
				return (
					<div key={key}>
						<GallerySection
							sectionTitle={section.title}
							subTitle={section.subTitle}
							images={section.images}
							text={section.text}
							darkTheme={section.darkTheme}
							useFor={!section.darkTheme}
							highlights={section.highlights}
							des={section.text}
							doodles={section.doodles}
						/>
					</div>
				)
			})}
		</div>
	)
}

export default Gallery3
