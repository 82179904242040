import React from 'react'
import TierComparisonTable from './TierComparisonTable'
import useScreenDetector from '../../hooks/useScreenDetector'

import EnoughTalk_BottomDoodle_D_PNG from '../../images/enoughtalk/EnoughTalk_BottomDoodle_D.1628x646.png'
import EnoughTalk_BottomDoodle_D_WEBP from '../../images/enoughtalk/EnoughTalk_BottomDoodle_D.1628x646.webp'

import EnoughTalk_BottomDoodle_M_PNG from '../../images/enoughtalk/EnoughTalk_BottomDoodle_M.984x562.png'
import EnoughTalk_BottomDoodle_M_WEBP from '../../images/enoughtalk/EnoughTalk_BottomDoodle_M.984x562.webp'

// import Border from '../../components/Border'

const EnoughTalk = () => {
	// #TODO
	const {isMobile} = useScreenDetector()

	// console.log('EnoughTalk: isMobile2 = ', isMobile2, 'isMobile = ', isMobile)

	return (
		<div id='tiers' className='relative flex flex-col justify-center items-center overflow-hidden'>
			<div className='relative w-full flex flex-col justify-center items-center max-w-screen-lg overflow-visible'>
				<div className='relative w-full px-8 max-w-3xl flex flex-col justify-center items-center z-10'>
					<h1 className='section-title !max-w-4xl text-left desktop:text-center desktop:text-6xl'>
						<p className='contents text-e_red'>{'ENOUGH TALK, '}</p>
						<p className='contents'>{"LET'S BRING YOUR CARICATURE TO "}</p>
						<p className='contents text-e_red'>{'LIFE!'}</p>
					</h1>
				</div>
				{/* <img
                    src={isMobile ? EnoughTalk_ExcitedWomen_M : EnoughTalk_ExcitedWomen_D}
                    className='z-20 absolute 
                    w-auto h-[80%] max-h-[90vh]
                    bottom-0 left-0 
                    desktop:scale-x-[1] 
                    |scale-x-[-1] 
                    -translate-x-[60%] 
                    '
                    alt=''
                /> */}
				<TierComparisonTable />
				<div className='relative w-full flex justify-center items-center overflow-hidden max-w-6xl '>
					{/* <div className=''> */}
					<picture className='w-full'>
						<source media='(max-width: 768px)' srcSet={EnoughTalk_BottomDoodle_M_WEBP} type='image/webp' />
						<source media='(min-width: 769px)' srcSet={EnoughTalk_BottomDoodle_D_WEBP} type='image/webp' />
						<source media='(max-width: 768px)' srcSet={EnoughTalk_BottomDoodle_M_PNG} type='image/png' />
						<source media='(min-width: 769px)' srcSet={EnoughTalk_BottomDoodle_D_PNG} type='image/png' />
						<img
							className='w-full'
							alt='Bottom Doodle'
							loading='lazy'
							width={isMobile ? 984 : 1628}
							height={isMobile ? 562 : 646}
							srcSet={`${EnoughTalk_BottomDoodle_M_PNG} 768w, ${EnoughTalk_BottomDoodle_D_PNG} 1628w`}
							src={isMobile ? EnoughTalk_BottomDoodle_M_PNG : EnoughTalk_BottomDoodle_D_PNG}
						/>
					</picture>
					{/* </div> */}
				</div>
			</div>
			{/* <Border /> */}
		</div>
	)
}

export default EnoughTalk
