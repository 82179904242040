import React from 'react'
// eslint-disable-next-line no-unused-vars
import ReactGA from 'react-ga4'

// import './footer.css'
// import {CopyCop, FiverrIcon} from '../../img'
// eslint-disable-next-line no-unused-vars
import {CopyCop, CopyCopMin} from '../images'
import {FaInstagram, FaTelegram} from 'react-icons/fa' // these are icons from FontAwesome

// import DividerWave from '../../components/divider_wave/divider_wave'
// import {useWhyDidYouUpdate} from '../../hooks/useWhyDidYouUpdate'
// eslint-disable-next-line no-unused-vars
import {Link} from 'react-router-dom'

const FooterLink = ({link, Icon}) => {
	return (
		<a href={link} target='_blank' rel='noopener noreferrer' className='text-gray-300 mx-3'>
			<Icon size={32} />
		</a>
	)
}

const social_links = {
	tg: {link: 'https://t.me/knapp_cartoons', handle: 'AdelezCartoon', icon: FaTelegram},
	ig: {link: 'https://instagram.com/knapp.cartoons', handle: 'knappstudio.cartoons', icon: FaInstagram},
}

// import { isChrome} from '../../util/browserCheck'
const Footer = (props) => {
	// useWhyDidYouUpdate('footer', props)
	// const d = new Date()
	// const currentYear = d.getFullYear()
	return (
		// TODO return sticky bottom-0 z-footer later
		<footer className='relative bg-black pt-6 w-full z-40'>
			{/* <DividerWave direction='down' /> */}
			<div className='flex items-center pl-10 p-4 sticky bottom-0'>
				<div className='img'>
					{/* <CopyCop className='fill-white w-full h-full doodle max-h-[50vh]' /> */}
					<CopyCopMin className='fill-white w-full h-full doodle max-h-[50vh]' />
				</div>
				<div className='flex justify-center mt-2 social-buttons'>
					<div className='text-gray-300 text-sm px-6'>Follow us on social media to stay up to date on new caricatures and specials!</div>
					<div className='flex p-4'>
						<FooterLink link={social_links.tg.link} Icon={social_links.tg.icon} />
						<FooterLink link={social_links.ig.link} Icon={social_links.ig.icon} />
						{/* <FooterLink link={social_links.ig.link} Icon={FaTwitter} /> */}
						{/* <FooterLink link={'https://fiverr.com/' + ig_handle} Icon={FiverrIcon} /> */}
					</div>
				</div>
			</div>
			<div className='copyright text-sm my-2 p-4 flex-to-center flex-col'>
				<p className='text-gray-300'>Copyright &copy; {new Date().getFullYear()} Knapp's Cartooning Studio</p>
				<p className='text-gray-300'>
					Made with <span className='text-red-600'>&hearts;</span> by{' '}
					<a href='https://meir.pro/?s=knappstudio' target='_blank' rel='noopener noreferrer'>
						Meir.pro
					</a>
				</p>
				{/* <p className='text-gray-400'>
					<Link to='/terms' className='px-4'>
						Terms of Service
					</Link>
					|
					<Link to='/privacy' className='px-4'>
						Privacy Policy
					</Link>
				</p> */}
			</div>
		</footer>
	)
}

export default Footer
