import React from 'react'
import {useNavigate} from 'react-router-dom'
import {scrollIntoView} from '../../utils/scroll_into_view'

const TierButton = function TierButton({tier}) {
	// const TierButton = memo(function TierButton({tier}) {
	const navigate = useNavigate()
	const gotoContact = () => {
		scrollIntoView(null, '#contact')
		navigate(`/contact?get=${tier}&from=table`)
	}
	return (
		<button
			className='font-lucky text-xl pb-2 px-4	pt-3 bg-e_blue text-main cursor-pointer border-none rounded appearance-none select-none mt-auto w-fit mx-auto'
			onClick={gotoContact}>
			Get now!
		</button>
	)
}

const ImageElement = function ImageElement({className, image, name}) {
	if (!image) {
		return null // or a placeholder element
	}
	return (
		<>
			{/* <image.PictureElement /> */}
			{/* <div className={`aspect-square object-center-center object-cover`}>*/}
			<picture className={`${className}`}>
				{React.Children.map(image.pictureElement.props.children, (child) => {
					if (child.type === 'img') {
						return React.cloneElement(child, {
							className: `rounded-lg aspect-[3/4] h-full object-center object-cover w-full`,
						})
					}
					return child
				})}
			</picture>
		</>
	)
}

export const TierInfo = ({tier, handleClick}) => {
	// export const TierInfo = memo(function TierInfo({tier, handleClick}) {
	const {name, image, description, price: tierPrice, features, isPopular} = tier

	return (
		<div className='tier-row relative flex flex-col items-center mt-6 md:h-full'>
			{isPopular && (
				// <div className=''>
				<h5 className='absolute top-0 -mt-5 py-1 px-2 rounded text-xs bg-e_blue text-white font-lucky'>Most Popular</h5>
				// </div>
			)}
			<div
				onClick={() => handleClick(tier)}
				className='relative h-full flex flex-row justify-between items-center content-center font-lucky w-full p-2 '
				htmlFor={name}>
				<ImageElement className='md:hidden w-2/5' image={image} name={name} />
				<div className='flex flex-col justify-between items-center content-center w-3/5 h-full md:w-full'>
					<div className='flex flex-col items-center'>
						<h1 className='text-4xl'>{name}</h1>
						<ImageElement className='hidden md:inline w-4/5' image={image} name={name} />
					</div>
					<div className='flex-grow flex flex-col justify-center'>
						<ul className='list-disc pl-5 font-regular'>
							{features?.map((feature, index) => (
								<li key={index}>{feature}</li>
							))}
						</ul>
						<p className='font-regular mx-auto px-4'>{description}</p>
					</div>
					<div className='flex flex-col items-center'>
						<span className='text-2xl md:text-3xl md:pl-2 py-2'>{tierPrice}</span>
						<TierButton tier={name} />
					</div>
				</div>
			</div>
		</div>
	)
}
// })
