// import {Navbar} from './components'
// import {isIE, isiOS} from './utils/browserCheck'
import {isIE} from './utils/browserCheck'
import {removeNoScript} from './utils/pageCleanup'
// eslint-disable-next-line
import React, {createContext, useCallback, useEffect, useState} from 'react'
// import {useEventListener} from './utils/useEventListener'
// import {useWhyDidYouUpdate} from './utils/useWhyDidYouUpdate'

// eslint-disable-next-line
import {BrowserRouter, useLocation} from 'react-router-dom'

// import {scrollIntoView} from './utils/scroll_into_view'
// eslint-disable-next-line
import {useFormik} from 'formik'
// import {UserProvider} from './context/UserContext'
// eslint-disable-next-line
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
// eslint-disable-next-line no-unused-vars
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
// import ReactGA from 'react-ga'
import {NotificationProvider} from './context/NotificationContext'

// eslint-disable-next-line
import Router from './pages/index.js'
// import {UIProvider} from './context/UIContext.js'
// import ReactGA from 'react-ga4'

// eslint-disable-next-line
const queryClient = new QueryClient()
// const Hero = React.lazy(() => import('./containers/hero/hero.js'))
// const About = React.lazy(() => import('./containers/about/about.js'))
// const Gallery = React.lazy(() => import('./containers/gallery/gallery.js'))
// const Testimonials = React.lazy(() => import('./containers/testimonies/testimonials.js'))
// const Specials = React.lazy(() => import('./containers/specials/specials.js'))
// const Tiers = React.lazy(() => import('./containers/tiers/tiers.js'))
// const FAQ = React.lazy(() => import('./containers/faq/faq.js'))
// const Contact = React.lazy(() => import('./containers/contact/contact.js'))
// const Footer = React.lazy(() => import('./containers/footer/footer.js'))

// ReactGA.initialize([
// 	{
// 		trackingId: 'GTM-MFJ7R45',
// 		testMode: true,
// 		//   gaOptions: {...}, // optional
// 		//   gtagOptions: {...}, // optional
// 	},
// 	{
// 		trackingId: 'G-NNH33134QS',
// 		testMode: true,
// 	},
// 	{
// 		trackingId: 'G-DPW6DFQN7D',
// 		testMode: true,
// 	},
// ])

const FormContext = createContext()

// Send pageview with a custom path
// ReactGA.send({hitType: 'pageview', page: '/about', title: 'About Page'})

function App() {
	const historyController = (e) => {
		console.log('app historyController', e)
	}
	window.onpopstate = (e) =>
		setTimeout(() => {
			historyController(e)
		}, 0)

	removeNoScript()

	const formik = useFormik({
		initialValues: {
			name: '',
			email: '',
			message: '',
		},
		// onSubmit: (values) => {
		// 	// Make API call here with values
		// },
	})

	if (isIE) return <div>IE is not supported. Use Chrome/Firefox/Opera.</div>

	return (
		<NotificationProvider>
			{/* <UIProvider> */}
			{/* <UserProvider> */}
			<FormContext.Provider value={formik}>
				<Router />
				{/* <QueryClientProvider client={queryClient}> */}
				{/* <RouteResolver routes={routes} defaults={defaultRoutes} /> */}
				{/* Nav={Nav} /> */}
				{/* <ReactQueryDevtools /> */}
				{/* </QueryClientProvider> */}
			</FormContext.Provider>
			{/* </UIProvider> */}
			{/* </UserProvider> */}
		</NotificationProvider>
	)
}

export default App
