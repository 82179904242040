// eslint-disable-next-line no-unused-vars
import React, {useEffect, useRef, useState} from 'react'
import Border from '../components/Border'
import {marketingDoodle, meaningfulTouchDoodle, unforgettableGiftDoodle, updateSocialDoodle} from '../images'

import Pros_Marketing_PNG from '../images/pros/Pros_Marketing.png'
import Meaningful_Touch_PNG from '../images/pros/Pros_PersonalTouch.png'
import Unforgettable_Gift_PNG from '../images/pros/Pros_UnforgettableGift.png'
import Update_Social_PNG from '../images/pros/Pros_SocialMedia.png'

import Pros_Marketing_WEBP from '../images/pros/Pros_Marketing.webp'
import Meaningful_Touch_WEBP from '../images/pros/Pros_PersonalTouch.webp'
import Unforgettable_Gift_WEBP from '../images/pros/Pros_UnforgettableGift.webp'
import Update_Social_WEBP from '../images/pros/Pros_SocialMedia.webp'

import Pros_Marketing_Thumb from '../images/pros/Pros_thumb_Marketing-optimised.webp'
import Meaningful_Touch_Thumb from '../images/pros/Pros_thumb_PersonalTouch-optimised.webp'
import Unforgettable_Gift_Thumb from '../images/pros/Pros_thumb_UnforgettableGift-optimised.webp'
import Update_Social_Thumb from '../images/pros/Pros_thumb_SocialMedia-optimised.webp'

// import Test_PNG from '../images/pros/Test-optimised.png'
// import Test_WEBP from '../images/pros/Test-optimised.webp'
// import Test_Thumb from '../images/pros/Test_thumb.webp'

import ImageLoader from '../utils/imageLoader'

const ProsPart = ({title, text, Doodle, artPNG, artWEBP, placeholder}) => {
	return (
		<div className='grid grid-cols-1 w-full md:grid-cols-2 gap-2 items-center justify-center max-w-screen-lg'>
			<div className='flex flex-row justify-center md:justify-end items-start'>
				<div className='w-24 h-auto relative mr-4'>
					<Doodle />
				</div>
				<div className='w-[15rem]'>
					<h1 className={` text-black font-lucky text-2xl`}>{title}</h1>
					<p>{text}</p>
				</div>
			</div>
			<div className='h-auto md:h-full relative mb-8 md:mb-0'>
				<ImageLoader
					artPNG={artPNG}
					artWEBP={artWEBP}
					placeholder={placeholder}
					altTitle={title}
					className={'object-contain max-h-[50vh] h-fit w-full aspect-video'}
				/>
			</div>
		</div>
	)
}

// Update parts array to include placeholder images
const parts = [
	{
		title: 'UNFORGETTABLE GIFT',
		text: 'Why get the same old generic gift when you can get something creative, meaningful, unique and tailor-made.',
		Doodle: unforgettableGiftDoodle,
		artPNG: Unforgettable_Gift_PNG,
		artWEBP: Unforgettable_Gift_WEBP,
		placeholder: Unforgettable_Gift_Thumb, // Add placeholder
	},
	{
		title: 'MEANINGFUL AND PERSONAL TOUCH',
		text: 'Preserve that special moment or event in a personal timeless way that really touches the heart.',
		Doodle: meaningfulTouchDoodle,
		artPNG: Meaningful_Touch_PNG,
		artWEBP: Meaningful_Touch_WEBP,
		placeholder: Meaningful_Touch_Thumb, // Add placeholder
	},
	{
		title: 'MARKETING',
		text:
			'Take advantage of the universal appeal of caricature and cartoon designs to give your brand presence a fun boost and distinct advantage above the competition.',
		Doodle: marketingDoodle,
		artPNG: Pros_Marketing_PNG,
		artWEBP: Pros_Marketing_WEBP,
		placeholder: Pros_Marketing_Thumb, // Add placeholder
	},
	{
		title: 'UPDATE SOCIAL',
		text: 'Update your social media avatars to a timeless and fun personal cartoon caricature that will stand out amongst the rest.',
		Doodle: updateSocialDoodle,
		artPNG: Update_Social_PNG,
		artWEBP: Update_Social_WEBP,
		placeholder: Update_Social_Thumb, // Add placeholder
	},
	// {
	// 	title: 'test',
	// 	text: 'test test test.',
	// 	Doodle: unforgettableGiftDoodle,
	// 	artPNG: Test_PNG,
	// 	artWEBP: Test_WEBP,
	// 	placeholder: Test_Thumb, // Add placeholder
	// },
]

const ProsSection = () => {
	return (
		<div className='relative flex flex-col justify-center content-center items-center mx-auto'>
			<div className='relative grid grid-cols-1 w-full gap-8 auto-rows-fr pr-0 lg:pr-28 doodle'>
				{parts.map((part, index) => (
					<ProsPart
						key={index}
						title={part.title}
						text={part.text}
						Doodle={part.Doodle}
						artPNG={part.artPNG}
						artWEBP={part.artWEBP}
						placeholder={part.placeholder}
					/>
				))}
			</div>
			<Border />
		</div>
	)
}

export default ProsSection
